'use client';

import { useEffect, useRef, useState } from 'react';

import { Paragraph } from '@/components/dom/text-elements';
import { useModal } from '@/components/providers/Modal';
import { useNewFeatureAnnouncement } from '@/components/providers/NewFeature';
import { Modal, ModalProps } from '@/components/ui/Modal';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { NewFeatureAnnouncementsStorageKeyMap, NewFeatureAnnouncementTypes } from '@/lib/constants/new-features';
import { ModalTypes } from '@/types/tracking';

type NewFeatureModalProps = {
    modalProps?: Omit<ModalProps, 'children'>;
    featureDescription: Array<React.ReactNode>;
    descriptionWrapperClassName?: string;
    descriptionCopyClassName?: string;
    featureKey: NewFeatureAnnouncementTypes;
};

export const NewFeatureModal = ({
    featureDescription,
    descriptionWrapperClassName,
    descriptionCopyClassName,
    modalProps,
    featureKey,
}: NewFeatureModalProps) => {
    const { newFeatures, updateNewFeatures } = useNewFeatureAnnouncement();
    const copyRef = useRef<HTMLDivElement>(null);
    const { hideModal } = useModal();
    const { components } = usePosthogTracking();
    const [descriptionText, setDescriptionText] = useState('');
    const hideModalAction = () => {
        updateNewFeatures(featureKey, true);
        hideModal();
    };
    const hasViewedModal: boolean =
        newFeatures[NewFeatureAnnouncementsStorageKeyMap[featureKey] as NewFeatureAnnouncementTypes];

    // Convert description react nodes to strings for tracking
    useEffect(() => {
        if (copyRef.current) {
            const innerText = copyRef.current.innerText ? copyRef.current.innerText.replace(/\n/gi, ' ') : '';

            setDescriptionText(innerText || '');
        }
    }, []);

    return (
        !hasViewedModal && (
            <Modal
                {...modalProps}
                modalClassName="max-w-[500px] flex flex-col gap-6"
                onModalClose={hideModalAction}
                trackingProperties={{
                    component: components.NEW_FEATURE_MODAL,
                    description: descriptionText,
                    featureType: featureKey,
                    modalType: ModalTypes.NEW_FEATURE,
                }}
            >
                <header className="flex items-center justify-between">
                    <span className="bg-analyst-blue text-white font-brand-bold text-sm uppercase rounded-full px-3 py-2 text-center tracking-wider">
                        New feature
                    </span>
                </header>

                <div
                    ref={copyRef}
                    className={cn('flex flex-col gap-3', descriptionWrapperClassName)}
                >
                    {featureDescription.map((description, index: number) => (
                        <Paragraph
                            key={`new-feature-description-copy-${index}`}
                            className={cn('m-0 text-black font-brand-md', descriptionCopyClassName)}
                        >
                            {description}
                        </Paragraph>
                    ))}
                </div>
            </Modal>
        )
    );
};
