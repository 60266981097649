import { createPortal } from 'react-dom';

import { Nullable } from '@/types/nullable';

interface PortalProps {
    el: Nullable<HTMLElement>;
    children: React.ReactNode;
}

export default function Portal({ el, children }: PortalProps) {
    return el && createPortal(children, el);
}
