'use client';

import { useEffect, useRef, useState } from 'react';

import Portal from '@/components/global/portal';
import { useModal } from '@/components/providers/Modal';
import IconButton from '@/components/ui/IconButton';
import useOutsideClick from '@/helpers/hooks/useOutsideClick';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type AllTrackingPropertiesTypes } from '@/types/tracking';

export interface ModalProps {
    children: React.ReactNode;
    modalClassName?: string;
    overlayClassName?: string;
    trackingProperties?: AllTrackingPropertiesTypes;
    disableHideOnOutsideClick?: boolean;
    onModalClose?: () => void;
}

export const Modal = ({
    children,
    modalClassName,
    overlayClassName,
    trackingProperties = {} as AllTrackingPropertiesTypes,
    disableHideOnOutsideClick = false,
    onModalClose,
}: ModalProps) => {
    const [el, setEl] = useState<HTMLElement | null>(null);
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const modalRef = useRef(null);
    const { hideModal } = useModal();
    const hideModalAction = () => {
        hideModal();
        onModalClose?.();
        trackManualEvent({
            eventType: eventTypes.HIDE_MODAL,
            trackingProperties,
        });
    };
    const hideModalActionOnOutsideClick = () => {
        if (!disableHideOnOutsideClick) {
            hideModalAction();
        }
    };

    useOutsideClick(modalRef, hideModalActionOnOutsideClick);

    // Here, we add and remove the modal from the DOM
    // so no need to track if it's open or not before
    // firing off the tracking event
    useEffect(() => {
        trackManualEvent({
            eventType: eventTypes.SHOW_MODAL,
            trackingProperties,
        });
    }, [eventTypes.SHOW_MODAL, trackManualEvent, trackingProperties]);

    useEffect(() => {
        const rootElem = document.getElementById('root');
        setEl(rootElem);
    }, [setEl]);

    return (
        <Portal el={el}>
            <div
                role="dialog"
                aria-modal="true"
                className={cn(
                    'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center animate-fadeIn px-4 md:px-10 z-9999',
                    overlayClassName
                )}
            >
                <div
                    ref={modalRef}
                    className={cn(
                        'bg-white shadow-lg p-6 w-full relative rounded-lg border border-analyst-dark-lavender animate-fadeIn',
                        modalClassName
                    )}
                >
                    <IconButton
                        onClick={hideModalAction}
                        iconType="close"
                        className=" absolute top-4 right-4 z-10"
                        iconProps={{
                            color: 'blueYves',
                            size: 'lg',
                        }}
                        tracking={{
                            eventType: eventTypes.HIDE_MODAL,
                            trackingProperties,
                        }}
                    />
                    {children}
                </div>
            </div>
        </Portal>
    );
};
